<template>
<div :style="{'--themecolor':themecolors}">
    <div class="intro" id="scroll"  v-if="!loading">
        <div style="min-height:95%;">
            <div class="top">
                <div class="flex-center between info" style="width: 100%;">
                    <div class="avatar flex-center" @click="imagePreview(attendIntro.avatar_url)">
                        <img :src="attendIntro.avatar_url" alt="">
                    </div>
                    <div class="user-info">
                        <div class="user-name">
                            <img src="../../assets/attend/name-icon.svg" alt="">
                            <span>{{attendIntro.name}}</span>
                        </div>
                        <div class="user-hospital">
                            <img src="../../assets/attend/unit.svg" alt="">
                            <span>{{attendIntro.hospital}}</span>
                        </div>
                    </div>
                </div>
                <div class="introduction">
                    <van-collapse v-model="activeNames"  v-if="attendIntro.introduction!=null">
                        <van-collapse-item title="嘉宾简介" name="1">
                            <p class="intro_p" v-html="attendIntro.introduction">
                            </p>
                        </van-collapse-item>
                    </van-collapse>
                    <div class="null-info" v-else @click="nullInfo">
                        <span>嘉宾简介</span>
                        <img src="@/assets/schedule/arrow-right.svg" alt="">
                    </div>
                </div>
                
            </div>
            <div v-if="assignment.length != 0">
                 <div class="particular"  v-for="(item,itemInd) in assignment" :key="itemInd">
                    <div class="date_title">
                        <div class="date_time">
                            <div class="title-right" style="display: inline; float: right; margin: 0 0 0.05rem 0.04rem;">
                                <span class="red" v-if="item.red"></span>
                                <span class="meet-num">{{item.list.length}}</span>
                            </div>
                            <span class="schedule-title">{{ item.title_format }}</span>
                        </div>
                    </div>
                     <div class="data-content">
                        <div class="date_main" v-for="(i,index) in item.list" :key="index"  @click="goRoom(i,item.title_format)">
                            <div style="flex:1">
                                <div class="date_main_title">{{i.agendum_title}}</div>
                                <div class="date_main_center"><van-icon name="todo-list-o" color="#4E5969" />{{i.venue_title}}</div>
                                <div class="date_main_center"><van-icon name="clock-o" color="#4E5969" />{{i.start_time_format}}-{{i.end_time_format}}</div>
                                <div class="date_main_center" v-if="i.venue_position"><van-icon name="location-o" color="#4E5969" />{{i.venue_position}}</div>
                                <div class="date_main_center"><van-icon name="manager-o" color="#4E5969" />{{i[mapRole(i.people_type)]}}</div>
                            </div>
                            <div class="date_main_arrow"><van-icon name="arrow" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-schedule" v-if="assignment.length == 0">
                <div class="title">
                    <span>暂无日程</span>
                    <div class="schedule-num">0</div>
                </div>
                <div class="no-img">
                    <img src="../../assets/attend/none-img.svg" alt="">
                    <div class="backtrack" @click.stop="goback">
                        返回
                    </div>
                </div>
            </div>
        </div>
        <Support></Support>
    </div>
     <van-loading :color="themecolors" :text-color="themecolors" v-else/>
    <sideBar></sideBar>
</div>
</template>

<script>
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import { ImagePreview } from "vant"; // 引入Vant图片预览组件
import { mapState } from 'vuex'
export default {
    components:{
        sideBar,
        Support,
    },
    data() {
        return{
            attendIntro:{},
            showTask: false,
            showIntro: false,
            showOne: true,
            showTwo: true,
            activeNames:['1'],
            active: [],
            assignment: [],
            placeCconfig:[],
            params:{},
            showAttention:false,
            loading:true
        }
    },
    created(){
        this.params = this.$route.query;
        this.attendIntro = JSON.parse(window.sessionStorage.getItem("attendIntro"));
        // this.getAcademicTasks()
        this.get_academic_assignments()
        if(sessionStorage.getItem('user_header') == 1 && this.$cookies.isKey('microtoken')){
            this.showAttention = true
        }else{
            this.showAttention = false
        }
    },
    computed: {
        ...mapState(['themecolors'])
    },
    methods:{
        mapRole(type) {
      switch(+type) {
        case 1:
          return 'venue_guest'
        case 2:
          return 'venue_host'
        case 3:
          return 'venue_discuss'
        case 4:
          return 'venue_president'
        case 5:
          return 'venue_address'
      }
    },
        // 获取会场信息
        get_academic_assignments(){
            this.$store.dispatch('get_academic_assignments', {
                page_id: this.$cookies.get('site_id'),
                people_id: this.$cookies.get('people_id')
            }).then(res=>{
                if(res.data.code==200){
                    let tempArr = [];
                    Object.values(res.data.data).forEach(item=>{
                       if (tempArr.indexOf(item.id) === -1) {
                            this.assignment.push({
                                id: item.id,
                                title_format:item.title_format,
                                list: [item]
                            });
                            tempArr.push(item.id);
                       }else{
                          this.assignment.forEach(j=>{
                            if(j.id==item.id){
                                j.list.push(item)
                            }
                          })
                       }
                    })
                    this.loading=false
               }
            })
        },
        //关注或取消关注日程
        async attentionSchedule(val, itemInd, ind, mInd){
            if(this.$cookies.isKey('microtoken') == false){
                this.$toast('请用微信浏览器打开！')
                return
            }
            let res
            if( this.assignment[itemInd].place_config[ind].timeline[mInd].follow_status == 0 ){
                res = await this.$store.dispatch('add_follow_the_schedule', {
                    id: val.id,
                    site_id: this.$route.query.site_id,
                    page_id: this.$route.query.page_id,
                    module_id: this.$route.query.module_id
                })
            }else{
                res = await this.$store.dispatch('delete_follow_the_schedule', val.id)
            }
            if(res.data.code == 200){
                let Timeline = JSON.parse(window.sessionStorage.getItem("scheduleTimelineCurrentItem"))
                if(this.assignment[itemInd].place_config[ind].timeline[mInd].follow_status == 0){
                    this.assignment[itemInd].place_config[ind].timeline[mInd].follow_status = 1
                    if(Timeline.id == this.assignment[itemInd].place_config[ind].timeline[mInd].id){
                        Timeline.follow_status = 1
                        window.sessionStorage.setItem("scheduleTimelineCurrentItem",JSON.stringify(Timeline))
                    }
                }else{ 
                    this.assignment[itemInd].place_config[ind].timeline[mInd].follow_status = 0
                    if(Timeline.id == this.assignment[itemInd].place_config[ind].timeline[mInd].id){
                        Timeline.follow_status = 0
                        window.sessionStorage.setItem("scheduleTimelineCurrentItem",JSON.stringify(Timeline))
                    }
                }
                this.$toast(res.data.message)
            }else{
                this.$toast(res.data.message)
            }
        },
        // 跳转
        goRoom(item,title){
             window.sessionStorage.setItem("scheduleTimelineCurrentItem",JSON.stringify(item))
            this.$router.push({name: 'scheduleInfo',query:{
                id:item.venue_id,
                title:title
            }});
        },
        // 转日期格式
        timestampToTime (time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let date = new Date(time * 1000) 
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return MM + '-' + d
        },
        // 转星期格式前
        timestampToweek (time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let date = new Date(time * 1000) 
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d
        },
        // 转换日期为星期
        getWeek(dateString) {
            let dateArray = dateString.split("-");
            let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
            return "周" + "日一二三四五六".charAt(date.getDay());
        },
        /** 时间段范围 */
        isDuringDate(beginDateStr, endDateStr) {
            var curDate = new Date(),
                beginDate = new Date(beginDateStr),
                endDate = new Date(endDateStr);
            if (curDate >= beginDate && curDate <= endDate) {
                return true;
            }
            return false;
        },
        goLive(url){
            if(!url){
                return
            }
            location.href = url
        },
        imagePreview(img_url){
            let img = [];
            img.push(img_url)
            ImagePreview({
                images:img, //需要预览的图片 URL 数组 
                showIndex:false, //是否显示页码
                loop:false, //是否开启循环播放
                closeable: true,  // 显示关闭按钮
                startPosition:0, //图片预览起始位置索引
            });
        },
        //展开
        goback(){
            this.$router.go(-1)
        },
        unfold(index,val){
            if(index == 1){
                if(val == false){
                    this.showOne = false
                }else{
                    this.showOne = true
                }
            }else{
                if(val == false){
                    this.showTwo = false
                }else{
                    this.showTwo = true
                }
            }
        },
        nullInfo(){
            this.$toast('暂无嘉宾简介')
        }
    }
}
</script>

<style lang="less" scoped>
@Color:var(--themecolor);
.flex-center{
    display: flex;
    align-items: center;
}
.between{
    justify-content: space-between;
}
.intro{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding: .15rem .15rem 0 .15rem;
    background: #f2f6f8;
    overflow-y: scroll;
    .top{
        background: #fff;
        padding: .15rem;
        border-radius: .06rem;
        margin-bottom: .15rem;
        background:url('../../assets/attend/info-line.svg') no-repeat top right,linear-gradient(#dde5ef 0%, #fff 100%);
        position: relative;
        p{
            font-size: .12rem;
        }
        .info-line{
            position: absolute;
            top: 0px;
            right: 0px;
        }
        .info{
            z-index: 99;
            justify-content: flex-start;
        }
        .introduction{
            margin-top: .3rem;
            border-top: 1px #D5D7E4 solid;
            /deep/.van-collapse{
                .van-collapse-item{
                    .van-cell{
                        background-color:rgba(0,0,0,0)!important;
                        padding: .15rem 0 0;
                        .van-cell__title{
                            span{
                                color:#1F2129  ;
                                font-size: .14rem;
                            }
                        }
                        .van-cell__right-icon{
                            color:#1F2129  ;
                            &::before{
                                transform: rotate(0deg);
                            }
                        }
                    }  
                    .van-collapse-item__wrapper{
                        .van-collapse-item__content{
                            background: none;
                            padding: .11rem 0 .02rem;
                            p{
                                font-size: .14rem;
                                color: #666;
                            }
                        }
                    }
                    .van-collapse-item__title--expanded{
                        .van-cell__right-icon{
                            color:#1F2129  ;
                            &::before{
                                transform: rotate(90deg);
                            }
                        }
                    } 
                }
            }
            .null-info{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top:.15rem;
                span{
                    color: #1F2129;
                    font-size: .14rem;
                }
            }
        }
    }
    .top .avatar{
        width: .7rem;
        height: .7rem;
        border-radius: .6rem;
        overflow: hidden;
        img{
            width: 100%;
            height: auto;
        }
    } 
    .top .user-info{
        line-height: .25rem;
        max-width: 60%;
        margin-left:.6rem;
        .user-name{
            display: flex;
        }
        .user-hospital{
            display: flex;
            margin-top: .1rem;
        }
        span{
            margin-left: .16rem;
            font-size: .14rem;
            display: block;
            width: 100%;
            font-size: .14rem;
            color: #1F2129;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // overflow: hidden;
            // font-family: 'PingFangBold';
        }
    }
    .no-schedule{
        background: #fff;
        border-radius: .10rem;
        .title{
            display: flex;
            width: 100%;
            height: .44rem;
            align-items: center;
            justify-content: space-between;
            padding: 0 .16rem;
            border-bottom: .01rem solid #F5F6FA;
            span{
                font-size: .18rem;
                color: #1F2129;
                // font-family: 'PingFangBold';
            }
            .schedule-num{
                width: .2rem;
                height: .2rem;
                background: #F0F2FA;
                border-radius: 50%;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .12rem;
            }
        }
        .no-img{
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            img{
                margin-top: .6rem;
                width: 2.13rem;
                height: auto;
            }
            .backtrack{
                height: .5rem;
                width: 2.13rem;
                background:#F0F2FA ;
                color: #8F9DAF;
                font-size: .18rem;
                border-radius: .25rem;
                // font-family: 'PingFangBold';
                margin: .4rem 0;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
    }
    .bottom{
        border-radius: .06rem;
        padding: 0 .2rem;
        background: #fff;
        position: relative;
        /deep/ .van-cell__title{
            span{
                font-size: .14rem;
            }
        }
    }
    .bottom_margin{
        margin-top: .15rem;
    }
    .particular{
        margin-top: .15rem;
        border-radius: .08rem;
        background-color: #fff;
        width: 100%;
        .date_title{
            width: 100%;

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .1rem .17rem;
            border-bottom:0.01rem solid #F5F6FA;
            .date_time{
                flex: 1;
                color: #8F9DAF;
                font-size: .1rem;
                .schedule-title{
                    font-weight: 700;
                    font-size: .16rem;
                    color: #1F2129;
                    // margin-right: .11rem;
                    word-break: break-all;
                    // font-family: 'PingFangBold';
                }
            }
            .title-right{
                margin-left: auto;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .red{
                    width: 0.06rem;
                    height: .06rem;
                    display: block;
                    background: #FF5C60;
                    border-radius: 100%;
                    position: absolute;
                    top: -0.05rem;
                    right: -.06rem;
                }
                .meet-num{
                    width: .2rem;
                    height: .2rem;
                    border-radius: 100%;
                    background: @Color;
                    font-size:.12rem;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .data-content{
            padding-top: .16rem;
            .date_main{
               display: flex;
               align-items: center;
                justify-content: space-between;
               padding: .16rem;
               border-bottom:1px #F5F6FA solid;
               &:first-child{
                padding-top: 0;
               }
               &:last-child{
                border-bottom:none;
               }
                .date_main_title{
                    margin-bottom: .15rem;
                    font-size: .14rem;
                    color: #1D2129;
                }
                .date_main_center{
                    color:#4E5969;
                    font-size: .14rem;
                    margin-bottom: .04rem;
                }
                .van-icon{
                    font-size: .16rem;
                    margin-right: .1rem;
                }
                .date_main_arrow{
                     display: flex;
                    align-items: center;
                    .van-icon{
                        font-size: .18rem;
                        font-weight: 700;
                        color: #999;
                    }
                }
            }
        }
    }
}
.van-collapse{
    padding: 0;
}
/deep/ .van-collapse-item{
    &::after{
        border: none;
    }
    padding: 0;
    .van-cell .van-cell--clickable .van-collapse-item__title{
        padding: .1rem 0;
        &::after{
            display: none;
        }
    }
    .van-collapse-item__title--expanded::after{
        display: none;
    }
}
.van-hairline--top-bottom::after{
    border: none;
}
/deep/ .van-cell{
    padding: .1rem 0;
}
.live-gif{
    width: .24rem;
    height: auto;
}
/deep/.van-loading{
    position: absolute;
    top: 50%;
    left:50%;
    transform:translate(-50%,-50%);
}
</style>